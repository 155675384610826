<template>
  <div class="flex h-dvh max-md:flex-col">
    <header class="top-0 z-50 block max-md:sticky lg:inset-y-0 lg:w-64 lg:flex-col">
      <div class="relative z-30 flex h-full grow flex-col justify-between gap-y-5 bg-white px-3 py-4 shadow-[0_0_15px_0px_rgba(0,0,0,0.1)] md:overflow-y-auto md:py-9 lg:px-6 lg:py-12">
        <div>
          <div class="flex items-center justify-center max-md:justify-between">
            <NuxtLink :to="{name: 'index'}">
              <img
                class="mx-auto h-auto w-32 md:w-14 lg:w-40"
                src="@/assets/images/logo.svg"
                alt="ER-BOX by Elektroraad"
              >
            </NuxtLink>
            <UiButton
              class="md:hidden"
              label="Menu"
              :icon-prefix="openMenu ? 'XMark' : 'Hamburger'"
              @click="openMenu = !openMenu"
            />
          </div>
        </div>
        <div
          :class="openMenu ? 'max-md:opacity-100' : 'max-md:pointer-events-none max-md:opacity-0'"
          class="inset-0 z-40 w-full space-y-5 bg-white px-3 py-4 transition max-md:absolute max-md:top-16 max-md:h-screen md:contents"
        >
          <AppMenu v-if="user" />
          <AppMenuSettings @close="openMenu = false" />
        </div>
      </div>
    </header>

    <ClientOnly>
      <Notifications
        class="pointer-events-none mt-2 select-none"
        position="top center"
        :duration="4000"
        :max="4"
        :ignore-duplicates="true"
        width="100%"
      >
        <template #body="{item, close}">
          <UiAlert
            class="pointer-events-all mx-auto my-4 max-w-[400px]"
            :type="item.type"
            :title="item.title"
            @click="close"
          >
            <span>{{ item.text }}</span>
          </UiAlert>
        </template>
      </Notifications>
    </ClientOnly>

    <main class="flex-1">
      <slot />
    </main>
  </div>
</template>

<script setup>
import {Notifications} from '@kyvg/vue3-notification';
import {useRoute} from 'vue-router';

const route = useRoute();

const user = useSofieUserWhenAuthenticated();

const openMenu = ref(false);

watch(route, () => {
  openMenu.value = false;
});
</script>
