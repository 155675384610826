<template>
  <div class="flex flex-col justify-between gap-4">
    <ul class="text-sm">
      <li class="flex items-center gap-2">
        <UiIcon
          size="extra-small"
          name="User"
        />
        {{ user.name }}
      </li>
      <li class="flex items-center gap-2">
        <UiIcon
          size="extra-small"
          name="mdi:email-outline"
        />
        {{ user.email }}
      </li>
      <li
        v-if="user.phone_number"
        class="flex items-center gap-2"
      >
        <UiIcon
          size="extra-small"
          name="mdi:phone-outline"
        />
        {{ user.phone_number }}
      </li>
      <li
        v-if="user.organization"
        class="flex items-center gap-2"
      >
        <UiIcon
          size="extra-small"
          name="mdi:office-building-outline"
        />
        {{ user.organization.title }}
      </li>
    </ul>

    <hr>
    <div class="mb-2 text-sm font-medium">
      Wachtwoord wijzigen
    </div>

    <SofieAuthChangePasswordForm
      @success="onSuccess"
      @error="onError"
    />

    <div v-if="!user.two_factor_enabled">
      <div class="mb-2 text-sm font-medium">
        Tweefactorauthenticatie instellen
      </div>
      <p class="mx-auto max-w-sm text-center text-gray-500">
        Open een authenticator app op uw telefoon en scan de QR-code om een 6-cijferige code te genereren.
      </p>
      <div class="flex justify-center">
        <QrcodeVue
          v-if="qrString"
          :size="128"
          :value="qrString"
          class="my-5"
        />
      </div>
    </div>
    <div v-else>
      <div class="mb-2 text-sm font-medium">
        Tweefactorauthenticatie uitschakelen
      </div>
    </div>
    <FormKit
      v-if="!user.two_factor_enabled"
      type="form"
      :actions="false"
      form-class="w-full text-left"
      @submit="enableTwoFactor"
    >
      <div class="mt-6 flex flex-col gap-4 max-sm:flex-col">
        <FormKit
          name="otp"
          type="text"
          label="Authenticatiecode"
          outer-class="$reset grow"
          validation="required"
        />
        <FormKit
          type="submit"
          label="Tweefactorauthenticatie activeren"
        />
      </div>
    </FormKit>
    <FormKit
      v-else
      type="form"
      :actions="false"
      form-class="w-full text-left"
      @submit="disableTwoFactor"
    >
      <div class="flex flex-col gap-4 max-sm:flex-col">
        <FormKit
          name="otp"
          type="text"
          label="Authenticatiecode"
          outer-class="$reset grow"
          validation="required"
        />
        <FormKit
          type="submit"
          label="Tweefactorauthenticatie uitschakelen"
        />
      </div>
    </FormKit>
  </div>
</template>

<script setup lang="ts">
import QrcodeVue from 'qrcode.vue';

const user = useSofieUser();
const {getSession} = useAuth();

function onSuccess() {
  notify({
    type: 'success',
    text: 'Je wachtwoord is succesvol gewijzigd.',
  });
}

function onError() {
  notify({
    type: 'error',
    text: 'Er is iets fout gegaan. Probeer het later nog eens.',
  });
}

const qrString = ref<string | undefined>(undefined);

const sofie2FAGenerateSecretMutation = useSofie2FAGenerateSecretMutation({});

sofie2FAGenerateSecretMutation.onDone(({data}) => {
  if (!data || !data.generateTwoFactorSecret) {
    return;
  }

  qrString.value = `otpauth://totp/${user.value.email}?secret=${data.generateTwoFactorSecret.secret}&issuer=${data.generateTwoFactorSecret.app}`;
});

onMounted(() => {
  if (user.value.two_factor_enabled) {
    return;
  }

  sofie2FAGenerateSecretMutation.mutate();
});

const sofie2FAEnableMutation = useSofie2FAEnableMutation({});

sofie2FAEnableMutation.onDone(() => {
  notify({
    type: 'success',
    title: 'Tweefactorauthenticatie is succesvol ingesteld.',
  });
});

sofie2FAEnableMutation.onError(() => {
  notify({
    type: 'error',
    title: 'Er is een fout opgetreden.',
  });
});

async function enableTwoFactor(formData: { otp: string }) {
  await sofie2FAEnableMutation.mutate({
    ...formData,
  });
  await getSession();
}

const sofie2FADisableMutation = useSofie2FADisableMutation({});

sofie2FADisableMutation.onDone(() => {
  notify({
    type: 'success',
    title: 'Tweefactorauthenticatie is succesvol uitgeschakeld.',
  });
});

sofie2FADisableMutation.onError(() => {
  notify({
    type: 'error',
    title: 'Er is een fout opgetreden.',
  });
});

async function disableTwoFactor(formData: { otp: string }) {
  await sofie2FADisableMutation.mutate({
    ...formData,
  });
  await getSession();
}
</script>
