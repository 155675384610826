<template>
  <nav class="flex-1 md:mt-12">
    <ul role="list">
      <li
        v-for="item in menu"
        :key="item.name"
      >
        <AppMenuItem :item="item" />
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import type {RouteLocationRaw} from 'vue-router';

type MenuItem = {
  name: string
  icon: string
  to: RouteLocationRaw
}

const {isOrganizationManager, isManager} = useRole();

const menu = computed<MenuItem[]>(() => [
  {name: 'Dashboard', icon: 'Dashboard', to: {name: 'index'}},
  ...(isManager.value ? [{name: 'Organisaties', icon: 'OfficeBuilding', to: {name: 'organisaties'}}] : []),
  {name: 'Projecten', icon: 'Clipboard', to: {name: 'projecten'}},
  ...(isOrganizationManager.value || isManager.value ? [{name: 'Gebruikers', icon: 'UserGroup', to: {name: 'gebruikers'}}] : []),
]);
</script>
