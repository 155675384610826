<template>
  <div class="space-y-1 md:mt-12">
    <UiSlideOutPanel
      v-model:open="openProfilePanel"
      title="Profiel"
    >
      <FormUserSettings />
    </UiSlideOutPanel>
    <AppMenuItem
      v-if="user"
      :item="{name: 'Profiel', icon: 'User'}"
      @click="() => { emit('close'); openProfilePanel = !openProfilePanel }"
    />
    <hr>
    <AppMenuItem
      v-if="user"
      :item="{name: 'Uitloggen', icon: 'LogOut'}"
      @click="signOut({callbackUrl: '/auth/login'})"
    />
    <AppMenuItem
      v-else
      :item="{name: 'Inloggen', icon: 'LogIn', to: {name: 'auth-login', query: {callbackUrl: href}}}"
    />
  </div>
</template>

<script lang="ts" setup>
const user = useSofieUserWhenAuthenticated();
const openProfilePanel = ref(false);
const {signOut} = useAuth();
const {href} = useRequestURL();

const emit = defineEmits(['close']);
</script>
